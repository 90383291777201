<template>
  <div>
    <div class="mb-4 d-flex justify-content-between align-items-center">
      <h3>
        {{ $t('bookkeeping.bookkeepingManagement.unsentPayments.header') }}
      </h3>
      <el-tooltip
        :content="getTooltipContent"
        effect="dark"
        :disabled="!isSendingDisabled"
        :visible-arrow="false"
        placement="top"
      >
        <span>
          <Button :disabled="isSendingDisabled" type="primary" @click="sendModalOpen = true">
            {{ $t('bookkeeping.send') }}
          </Button>
        </span>
      </el-tooltip>
    </div>
    <Table
      v-loading="loading"
      :columns="columns"
      :data="tableData"
      show-index
      rounded
      border
      @row-click="paymentId = tableData[$event].id"
    />
    <DocumentSendingModal
      v-if="sendModalOpen"
      :type="BATCH_TYPE.PAYMENT"
      :docs="payments"
      :send-email="config.paymentSending.email"
      :from-date="paymentsPeriod.fromDate"
      :to-date="paymentsPeriod.toDate"
      @close="sendModalOpen = false"
      @send="handleSendEvent"
    />
    <EventMapModal v-if="paymentId" :activity="{ id: paymentId, type: 'payment' }" @close="paymentId = null" />
  </div>
</template>

<script>
import { getCurrentInstance } from 'vue';
import { DateTime } from 'luxon';

import { Table, Button } from '@/modules/core';
import { EventMapModal } from '@/modules/eventMapModal';
import { useTenancy } from '@/modules/auth';
import { useCurrency } from '@/modules/core/compositions/money-currency';

import { BATCH_TYPE } from '../batch';
import { useBatchSend } from '../composition/batch';
import { formatDate } from '../formatters';
import DocumentSendingModal from './DocumentSendingModal';

const TABLE_HEADERS = {
  DUE_DATE: 'dueDate',
  SUPPLIER: 'supplier',
  BILLING_COUNT: 'billingCount',
  DATE_PERIOD: 'datePeriod',
  TOTAL_AMOUNT: 'totalAmount',
};

export default {
  components: { Table, Button, DocumentSendingModal, EventMapModal },
  props: {
    payments: { type: Array, required: true },
    loading: { type: Boolean, required: true },
    config: { type: Object, required: true },
  },
  setup() {
    const root = getCurrentInstance().proxy;
    const { currentTenant } = useTenancy();
    const { sendBatch, onDone, onError } = useBatchSend();
    const { formatToCurrency } = useCurrency();

    onDone(() => root.$message.success(root.$t('bookkeeping.sendModal.sendMessages.success')));
    onError(() => root.$message.error(root.$t('bookkeeping.sendModal.sendMessages.error')));

    return {
      formatToCurrency,
      sendBatch,
      currentTenant,
    };
  },
  data() {
    return {
      BATCH_TYPE,
      sendModalOpen: false,
      paymentId: null,
    };
  },
  computed: {
    isSendingDisabled() {
      return !this.payments.length || !this.config?.paymentSending?.email;
    },
    getTooltipContent() {
      if (!this.config?.paymentSending?.email) {
        return this.$t('bookkeeping.sendModal.sendMessages.disabled', {
          type: this.$t('bookkeeping.bookkeepingManagement.tabs.payments'),
        });
      } else {
        return this.$t('bookkeeping.sendModal.sendMessages.emptyContent', {
          type: this.$t('bookkeeping.bookkeepingManagement.tabs.payments'),
        });
      }
    },
    columns() {
      return [
        {
          header: this.translateTableHeader('dueDate'),
          key: TABLE_HEADERS.DUE_DATE,
        },
        {
          header: this.translateTableHeader('supplier'),
          key: TABLE_HEADERS.SUPPLIER,
        },
        {
          header: this.translateTableHeader('billingCount'),
          key: TABLE_HEADERS.BILLING_COUNT,
        },
        {
          header: this.translateTableHeader('datePeriod'),
          key: TABLE_HEADERS.DATE_PERIOD,
        },
        {
          header: this.translateTableHeader('totalAmount'),
          key: TABLE_HEADERS.TOTAL_AMOUNT,
        },
      ];
    },
    tableData() {
      return this.payments.map(({ id, billingLinks, date, supplier, amount, isRefund }) => {
        const dateCount = billingLinks.reduce((isoMonthGroupsCount, { billing }) => {
          if (billing) {
            const isoMonthDate = DateTime.fromJSDate(new Date(billing.date)).toFormat('yyyy-LL');
            if (!isoMonthGroupsCount[isoMonthDate]) isoMonthGroupsCount[isoMonthDate] = 0;
            isoMonthGroupsCount[isoMonthDate] += 1;
          }
          return isoMonthGroupsCount;
        }, {});

        let maxDate = null;
        Object.entries(dateCount).forEach(([isoMonthDate, count]) => {
          if (!maxDate) {
            maxDate = isoMonthDate;
            return;
          }
          if (count > dateCount[maxDate]) {
            maxDate = isoMonthDate;
            return;
          }
        });

        return {
          id,
          [TABLE_HEADERS.DUE_DATE]: this.formatDate(date),
          [TABLE_HEADERS.SUPPLIER]: supplier.name,
          [TABLE_HEADERS.BILLING_COUNT]: billingLinks.length,
          [TABLE_HEADERS.DATE_PERIOD]: maxDate
            ? new Date(maxDate).toLocaleDateString(this.$i18n.locale, {
                month: 'short',
                year: 'numeric',
              })
            : '-',
          [TABLE_HEADERS.TOTAL_AMOUNT]: this.formatMoney(amount * (isRefund ? -1 : 1)),
        };
      });
    },
    paymentsPeriod() {
      const paymentsSortedByDate = [...this.payments].sort((a, b) => new Date(b.date) - new Date(a.date));
      const mostRecentPayment = paymentsSortedByDate[0];
      const leastRecentPayment = paymentsSortedByDate[paymentsSortedByDate.length - 1];

      return {
        fromDate: DateTime.fromISO(leastRecentPayment.date).toMillis(),
        toDate: DateTime.fromISO(mostRecentPayment.date).toMillis(),
      };
    },
  },
  methods: {
    formatDate,
    formatMoney(value) {
      return this.formatToCurrency(value) ?? '-';
    },
    async handleSendEvent() {
      await this.sendBatch({
        refs: this.payments.map(({ id }) => id),
        type: BATCH_TYPE.PAYMENT,
        businessId: this.currentTenant.id,
      });
      this.$emit('batch-sent');
      this.sendModalOpen = false;
    },
    translateTableHeader(columnKey) {
      return this.$t(`bookkeeping.bookkeepingManagement.unsentPayments.table.headers.${columnKey}`);
    },
  },
};
</script>
